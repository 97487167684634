const options =  {
    type: {
        'string': 'Строка',
        'object': 'Объект',
        'money': 'Деньги',
        'int': 'Целое число',
        'float': 'Число с точкой',
        'date': 'Дата',
        'datetime': 'Дата и время',
        'bool': 'Булев тип',
        '[]string': 'Набор строк',
        '[]object': 'Набор объектов',
        '[]money': 'Набор денег',
        '[]int': 'Набор целых чисел',
        '[]float': 'Набор чисел с точкой',
        '[]date': 'Набор дат',
        '[]datetime': 'Набор дат со временем',
        '[]bool': 'Набор переменных булева типа'
    }
};

export default options;
